<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:500px;background-color:#fff">
      <b-row class="mb-5">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps"/>
        </b-col>
        <b-col xl="12" lg="12" sm="12" md="12" v-if="!isLoading">
          <h3 class="content-header-title text-primary-dark">{{ $t('Bill Info') }}</h3>
          <div class="mt-4">
            <b-row class="p-2 bg-gray">
              <b-col xl="6" lg="6" sm="12" md="12" class="">
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Invoice NO') }} :</label><span>{{ invoice.ref_invoice }}</span></div>
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Invoice Created') }} :</label><span>{{ invoice.created_at }}</span></div>
              </b-col>
              <b-col xl="6" lg="6" sm="12" md="12" class="">
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Type') }} :</label><span>One-time-fee</span></div>
                <div class="flex-justify-content-space-between px-2"><label class="text-primary-dark font-weight-600 pr-2">{{ $t('Payment Status') }} :</label><span>{{ invoice.status_name }}</span></div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <div class="col-12 col-lg-8 mt-4">
              <label class="text-primary-dark font-weight-600">{{ $t('Bill to')}}</label>
              <b-row class="p-2">
                <div class="col-12">
                  <b-card title="" sub-title="">
                    <b-card-text class="font-weight-bold text-capitalize">{{ invoice.first_name }} {{ invoice.last_name }}</b-card-text>
                    <b-card-text class=""><label class="font-weight-bold pr-2">{{ $t('Address') }} :</label><span>{{ invoice.company_name }} {{ invoice.address }} {{ invoice.region_name }} {{ invoice.country_name }} {{ invoice.post_code }}</span></b-card-text>
                    <b-card-text class=""><label class="font-weight-bold pr-2">{{ $t('Phone number') }} :</label><span>{{ invoice.phone_number }}</span></b-card-text>
                    <b-card-text class=""><label class="font-weight-bold pr-2">{{ $t('Email') }} :</label><span>{{ invoice.email }}</span></b-card-text>
                  </b-card>
                </div>
                <div class="col-12 mt-4 d-none">
                  <label class="text-primary-dark font-weight-600 pr-2">{{ $t('Select Payment Currency') }} :</label>
                  <b-dropdown size="" :text="uppercase(paymentCurrency)" variant="primary">
                    <b-dropdown-item-button @click="selectPaymentCurrency('usd')">USD - US Dollar</b-dropdown-item-button>
                    <b-dropdown-item-button @click="selectPaymentCurrency('eur')" class="d-none">EUR - Euro</b-dropdown-item-button>
                    <b-dropdown-item-button @click="selectPaymentCurrency('inr')" class="d-none">INR - Indian Ruppe</b-dropdown-item-button>
                    <b-dropdown-item-button @click="selectPaymentCurrency('thb')">THB - Thai Baht</b-dropdown-item-button>
                  </b-dropdown>
                </div>
                <div class="col-12 mt-4">
                  <label class="text-primary-dark font-weight-600 pr-2">{{ $t('Orders') }}</label>
                  <div class="table-responsive" style="min-height: 60px;">
                    <b-table striped hover :items="orders" :fields="orderFields">
                      <template #cell(price)="row">
                        <span>
                          {{ getAmountByCurrency(row.item, 'price', invoice.paid_currency ) }}
                        </span>
                      </template>
                      <template #cell(total)="row">
                        <span>
                          {{ getAmountByCurrency(row.item, 'total', invoice.paid_currency ) }} <span class="text-uppercase">{{ invoice.paid_currency }}</span>
                        </span>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-row>
            </div>
            <div class="col-12 col-lg-4 mt-4">
              <b-row class="p-2">
                <b-col lg='12' class="orders-summary">
                  <b-card :title="$t('Order Summary')" :sub-title="$t('Calculate all purchases')" class="">
                    <div class="flex-justify-content-space-between mt-3">
                      <b-card-text class="text-title-bold">{{ $t('Sub total') }}</b-card-text>
                      <div class="columns">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'sub_total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <div class="flex-justify-content-space-between">
                      <b-card-text class="text-title-bold">{{ $t('Discount') }}</b-card-text>
                      <div class="columns">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'discount_amount', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <div class="flex-justify-content-space-between">
                      <b-card-text class="text-title-bold">{{ $t('Total') }}</b-card-text>
                      <div class="columns">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <div class="flex-justify-content-space-between">
                      <b-card-text class="text-title-bold">{{ $t('Include VAT/GST') }} ({{ invoice.vat_percent | numeral('0,0.00') }} %)</b-card-text>
                      <div class="columns">
                        <b-card-text class="text-amount">{{ getAmountByCurrency(invoice, 'vat', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></b-card-text>
                      </div>
                    </div>
                    <b-row>
                      <b-col>
                        <hr/>
                      </b-col>
                    </b-row>
                    <div class="flex-justify-content-space-between mt-3 mb-2">
                      <h5 class="text-title-bold">{{ $t('Grand total') }}</h5>
                      <div class="columns">
                        <h5 class="text-danger text-title-bold">{{ getAmountByCurrency(invoice, 'grand_total', invoice.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ invoice.paid_currency }}</span></h5>
                      </div>
                    </div>
                  </b-card>
                  <b-row class="mt-3">
                    <b-col>
                      <b-button block class="btn-danger-light btn-danger-light-shadow" size="lg" variant="danger" @click="paymentDetail(invoice.id)">
                        {{ $t('Payment Detail') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button variant="outline-secondary" class="mt-3" exact :to="{name: 'billing'}">
                {{ $t('Back') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col  xl="12" lg="12" sm="12" md="12" v-else>
          <b-row class="justify-content-center">
            <b-spinner class="text-primary-dark" label="Loading"></b-spinner>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Api from '../../../api/api'
import StepFormRegister from '../../../components/form/StepFormRegister'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Checkout',
  title: ' | Checkout',
  components: {
    StepFormRegister
  },
  data () {
    return {
      isLoading: true,
      currentStep: 0,
      titleListSteps: [this.$t('Bill Info'), this.$t('Payment'), this.$t('Finished')],
      loading: true,
      orderFields: [
        { key: 'item_code', label: this.$t('Item code') },
        { key: 'name', label: this.$t('Product') },
        { key: 'quantity', label: this.$t('Quantity') },
        { key: 'price', label: this.$t('Price') },
        { key: 'total', label: this.$t('Total') }
      ],
      orders: [],
      invoice: {},
      messageError: '',
      paymentCurrency: 'thb'
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    const invoiceId = this.$route.params.invoiceId
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
    this.invoiceDetail(invoiceId)
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    paymentDetail (invoiceId) {
      this.$router.push({ name: 'billingCheckoutPayment', params: { invoiceId: invoiceId } })
    },
    invoiceDetail (invoiceId) {
      this.isLoading = true
      Api.invoiceDetail({ invoiceId: invoiceId }).then((response) => {
        this.invoice = response.data
        this.paymentCurrency = this.invoice.paid_currency
        this.orderDetail(this.invoice.order)
        this.isLoading = false
      })
        .catch(() => {
          this.isLoading = false
          this.$notify({
            group: 'error',
            title: this.$t('Billing'),
            text: this.$t('No results')
          })
        })
    },
    orderDetail (orderId) {
      this.isLoading = true
      Api.orderDetail({ orderId: orderId }).then((response) => {
        this.orders = response.data.order_item_set
        this.isLoading = false
      })
        .catch(() => {
          this.messageError = ''
          this.isLoading = false
        })
    },
    getAmountByCurrency (item, field, currency) {
      const fieldName = field + '_' + currency
      return item[fieldName]
    },
    selectPaymentCurrency (currency) {
      this.paymentCurrency = currency
      Api.updateInvoice({ id: this.invoice.id, paid_currency: currency }).then((response) => {
        this.invoiceDetail(this.invoice.id)
        this.$notify({
          group: 'success',
          title: this.$t('Invoice'),
          text: this.$t('Save payment currency successful')
        })
        this.$bvModal.hide('modal-edit-bill-address')
      })
        .catch(() => {
          this.$notify({
            group: 'error',
            title: this.$t('Invoice'),
            text: this.$t('Save payment currency unsuccessful')
          })
        })
      console.log(currency)
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
